import { getExchangeUrl, useCurrentProperties, useMenuTypeList, useOriginMenuData, useSwitchParlayRoute } from '@/hooks/query';
import { useAccountInfo } from './query/account';
import { useSiteStore } from '@/store/site';
import { kenoSettings, sportLotterySettings } from '@/settings/lottery-settings';
import { UserInfo } from '@nf/types/account';
import { useGetSiteConfigs, useGetSiteConfigsWithSession } from './query/use-site-configs';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import { modalService } from '@/services/modal.service';
import { getForbiddenSport, isNumberGames, isVirtualSports } from '@/services/settings.service';
import { openCasinoRoyaleInNewWindow } from './query/use-casino-royale';
import { useAppearanceStore } from '@/store/appearance';
import { openESport } from '@/services/third-party-entrance.service';
import { useAuthToken } from '@/store/auth';
import { useGalaxyDeeplinkParams } from './use-galaxy-deeplink-params';
import { sportsbookService } from '@/services/sportsbook.service';
import { menuDefinition } from '@/types/menu-setting';
import { PromotionType } from '@nf/utils-common/constants';

export interface ProductItem {
	labelKey: string;
	path?: string; 
	onClick?: () => void;
	subMenu?: SubItem[];
}

export interface SubItem {
	labelKey: string;
    imagePath: string;
	onClick?: () => void;
}

export const useProductList = () => {    
	let productList = new Array<ProductItem>();
	const { user } = useAccountInfo();
	const authToken = useAuthToken();
	const isBefore = !Boolean(authToken);
	const { SiteConfigsData } = useGetSiteConfigs();
	const { SiteConfigsWithSessionData } = useGetSiteConfigsWithSession();
	const t = useTranslations('General');
	const [
		setIsParlayMode, 
		setIsOpenExchange,
		deepLinkSiteInfo
	] = useSiteStore(state => [
		state.setIsParlayMode, 
		state.setIsOpenExchange,
		state.deepLinkSiteInfo
	]);
	const { routePrefix: currentRoutePrefix, sport: currentSport } = useCurrentProperties();
	const view = useAppearanceStore(state => state.view);

	const parlayMenuTypeList = useMenuTypeList(true);
	const normalMenuTypeList = useMenuTypeList(false);	

	const sportDefaultPathData = useSwitchParlayRoute(false);
	const parlayDefaultPathData = useSwitchParlayRoute(true);

	const { menuResponse } = useOriginMenuData(false);

	useEffect(() => {
		productList = generateProductList();
	}, [currentRoutePrefix, currentSport, menuResponse]);

	const openCasinoRoyale = (sportId?: string, betTypeId?: string, category?: string) => {
		if (user && SiteConfigsData) {
			openCasinoRoyaleInNewWindow(user, SiteConfigsData, deepLinkSiteInfo.isApp, sportId, betTypeId, category);
		}
	}

	const generateProductList = () => {
		const list = new Array<ProductItem>();
		const order = Object.keys(menuDefinition);
		const defaultEvent =  normalMenuTypeList.find(menu => menu.modeId === 0);
		const parlayDefaultEvent = parlayMenuTypeList.find(menu => menu.modeId === 0);
		const specialEvents = normalMenuTypeList.filter(menu => menu.modeId !== 0);
		specialEvents?.sort((a, b) => {
			return order.indexOf(`M${a.modeId}`) - order.indexOf(`M${b.modeId}`)
		}).forEach(menuType => {
			list.push({
				labelKey: sportsbookService.makeMenuNodeResourceKey(SiteConfigsData?.EnableFilterSaba, menuType.modeId.toString()),
				path: menuType.path,
			});
		});
		/*var name=['lbl_AllLive','lbl_Sports','lbl_mixparlay','lbl_SoccerClub','#43','lbl_SabaPinG','lbl_VirtualSport','#161'];*/
	
		const virtualSportsMenu = menuResponse.filter(menu => isVirtualSports(menu.GameId));
		const NumberGamesMenu = menuResponse.filter(menu => isNumberGames(menu.GameId));
	
	
		if (defaultEvent) {
			list.push({
				labelKey: 'lbl_Sports',
				path: defaultEvent.path
			});
		}

		if (parlayDefaultEvent) {	
			list.push({
				labelKey: 'lbl_mixparlay',
				path: `/sports/${parlayDefaultPathData.sport}/${parlayDefaultPathData.market}`,
				onClick: () => setIsParlayMode(true)
			});
		}
		if (user?.ActStatus === 2 && SiteConfigsData?.EnableMyFavorite) {
			list.push({
				labelKey: 'lbl_favorite',
				path: '/favorites'
			});

		}
		if ((!user && SiteConfigsWithSessionData?.EnableExchangeForBefore) || user?.Exchange) {
			list.push({
				labelKey: 'lbl_Exchange',
				onClick: () => {
					if (isBefore) {
						modalService.openLoginModal();
					} else if (user?.ActStatus !== 2) {
						modalService.openMessageModal(t('lbl_deposit'));
					} else if (user?.ActStatus === 2 && user?.EnableExchangeTransfer) {
						setIsOpenExchange(true);
					} else {
						const newWindow = window.open('about:blank', '_blank');
	
						getExchangeUrl(true, user, SiteConfigsData).then(data => {
							if (data.ErrorCode != 0) {
								newWindow?.close();
							} else {
								if (newWindow) {
									newWindow.location = data.GameUrl ?? '';
									newWindow.focus();
								}
							}
						});
					}
				}
			});
		}
		if (user?.ActStatus === 2 && user?.EnableESportWeb && SiteConfigsData) {
			const esportsData = {
				siteId: SiteConfigsData.GetMessageID,
				umSource: t('lbl_GameUnderMaintain')
			};
			list.push({
				labelKey: 'lbl_ESports',
				onClick: () => openESport(esportsData)
			});
		}
	
		// if (user?.CanSeeAllPinGoal) {
		// 	list.push({
		// 		labelKey: 'lbl_SabaPinG',
		// 		onClick: openSabaPingGoal
		// 	});
		// }
		const forbiddenSports = getForbiddenSport(view);

		const enableVirtualSport = virtualSportsMenu.filter(menu => {
			return !forbiddenSports.includes(menu.GameId.toString());
		})
	
		if (user?.ActStatus === 2 && enableVirtualSport.length) {
			list.push({
				labelKey: 'lbl_VirtualSport',
				path: `/sports/${enableVirtualSport[0].GameId}/t`
			});
		}
	
		const enableNumberGame = NumberGamesMenu.filter(menu => {
			return !forbiddenSports.includes(menu.GameId.toString());
		})

		if (user?.ActStatus === 2 && enableNumberGame.length) {
			list.push({
				labelKey: 'lbl_161',
				path: `/sports/${enableNumberGame[0].GameId}/t`
			});
		}
	
		if (user?.VGamingMobi) {
			list.push({
				labelKey: 'lbl_VGamingMobiL',
				onClick: () => {
					if (user.ActStatus != 2) {
						modalService.openMessageModal(t('lbl_deposit'));
					} else {
						openCasinoRoyale('171', '0');
					}
				}
			});
		}
	
		const kenoProducts: ProductItem = {
			labelKey: kenoSettings.labelKey,
			subMenu: []
		};
	
		kenoSettings.subItems.forEach(kenoItems => {
			const permissionKey = user?.[kenoItems.permissionKey as keyof UserInfo] ?? 0; //MaxGame
	
			if (permissionKey) {
				kenoProducts.subMenu?.push({
					labelKey: kenoItems.labelKey,
					imagePath: kenoItems.imagePath,
					onClick: () => {
						const params = kenoItems.route.split(',');
						openCasinoRoyale(params[0], params[1]);
					}
				});
			}
		});
	
		if (kenoProducts.subMenu?.length) {
			list.push(kenoProducts);
		}
	
		const sportLotteryProducts: ProductItem = {
			labelKey: sportLotterySettings.labelKey,
			subMenu: []
		};
	
		sportLotterySettings.subItems.forEach(sportLotteryItems => {
			const permissionKey = user?.[sportLotteryItems.permissionKey as keyof UserInfo] ?? 0; //MaxGame
	
			if (permissionKey) {
				sportLotteryProducts.subMenu?.push({
					labelKey: sportLotteryItems.labelKey,
					imagePath: sportLotteryItems.imagePath,
					onClick: () => {
						const params = sportLotteryItems.route.split(',');
						openCasinoRoyale(params[0], params[1]);
					}
				});
			}
		});
	
		if (sportLotteryProducts.subMenu?.length) {
			list.push(sportLotteryProducts);
		}
	
		if (user?.CanSeeAdvantPlayVendorLobby || SiteConfigsData?.CanSeeAdvantPlayVendorLobby) {
			list.push({
				labelKey: 'lbl_Advantplay',
				onClick: () => {
					if (user?.ActStatus != 2) {
						modalService.openMessageModal(t('lbl_deposit'));
					} else {
						openCasinoRoyale('172');
					}
				}
			});
		}

		if (!isBefore && user?.ActStatus === 2 && SiteConfigsData?.EnableStreamerSchedule) {
			list.unshift({
				labelKey: 'lbl_Streamer',
				path: '/streamer',
			});
		}
				
		if (SiteConfigsData?.EnablePromotion ?? 0 > PromotionType.Close) {
			list.unshift({
				labelKey: 'lbl_ECPPromotion',
				path: '/promotion',
			});
		}

		return list;
	}

	productList = generateProductList();

	return productList;
};

export const use3rdPartyProductList = () => {
	const { user } = useAccountInfo();
	const view = useAppearanceStore(state => state.view);
	const forbiddenSport = getForbiddenSport(view);
	const thirdPartyGameList =[
		{ name: 'lbl_161', icon: '161', href: '/sports/161/l', hasLive: true, enable: !forbiddenSport.includes('161') && user?.NG  },
		{ name: 'lbl_164', icon: '164', href: '/sports/164/l', hasLive: true, enable: !forbiddenSport.includes('164') && user?.Happy5  },
		{ name: 'lbl_Keno', icon: '202', href: '/game-lobby/?sid=202&bid=0', enable: user?.Keno },
		{ name: 'lbl_KenoLottery', icon: '220', href: '/game-lobby/?sid=220&bid=0', enable: user?.KL },
		{ name: 'lbl_VGaming', icon: '171', href: '/game-lobby/?sid=171&bid=0', enable: user?.VGaming && (user?.ActStatus == 2) },
		{ name: 'lbl_Arcadia', icon: '165', href: '/game-lobby/?sid=165',  enable: user?.MiniStatus },
		{ name: 'lbl_LiveCasino', icon: 'game-lobby', href: '/game-lobby/?cid=2',  enable: user?.LCas },
		
	]
	return {  thirdPartyGameList }
}