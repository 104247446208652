import clsx from 'clsx';
import { useAppearanceStore } from '@/store/appearance';
import { ViewEnum } from '@/types';

type IconProps = {
	name: string;
	className?: string;
};

type SportsIconProps = {
	sportId: string | number;
	className?: string;
	isColorful?: boolean;
};


export const Icon = ({ name, className }: IconProps) => (
	<svg className={clsx(`icon icon--${name}`, className)}>
		<use xlinkHref={`#icon-${name}`}></use>
	</svg>
);

const sportSupportList: Array<string> = [
	'1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
	'11', '12', '13', '14', '15', '16', '17', '18', '18x', '19', '20',
	'21', '22', '23', '24', '25', '26', '28', '29', '30',
	'31', '32', '33', '34', '35', '36', '37', '38', '39', '40',
	'41', '42', '43', '43_0', '43_20', '43_25', '43_208', '43_24', '43_111', '43_16', '43_18', '43_27', '43_28', '43_29', '43_30', '43_32', '43_34', '43_37', '43_76', '43_99', '43_109', '43_114', '43_115', '43_116', '43_121', '43_150', '43_151', '43_152', '43_178', '43_215', '44', '45', '47', '48', '49', '50',
	'51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66',
	'97', '9995', '9996', '9997',
	'99',
	'161', '161_1', '161_2', '161_3', '164', '164_1', '168_1', '168_2', '169_2', 
	'165', '171', '172', '202', '220',
	'175', '179', '180', '186', '188', '190', '191', '192', '193', '194', '195', '196', '197', '221', '221_special',
	'993', '993_special', '997', '999', '997_special', '1_997', '1_997_special',
	'olympics', 'winter-olympics', 'world-cup', 'euro-cup', 'copa-america', 'asian-cup', 'womens-asian-cup', 'ameri-cup', 'nations-league', 'aff-cup', 'womens-aff-cup',
	'womens-world-cup', 'womens-euro', 'asia-games', 'fiba-world-cup', 'womens-fiba-world-cup',
	'sea-games', 'womens-eurobasket', 'womens-americup', 'womens-afrobasket', 'eurobasket',
	'afrobasket', 'commonwealth-games', 'asia-cup', 'womens-asia-cup', 'virtual-euro', 'virtual-copa', 'virtual-epl', 'virtual-tournament',
];

export const SportIcon = ({ sportId, className, isColorful }: SportsIconProps) => {
	const view = useAppearanceStore(state => state.view);
	const sportName = sportSupportList.includes(String(sportId)) ? sportId : 'default';
	return (
		<svg className={clsx('icon icon--sport', className)}>
			<use xlinkHref={`/images/svgs/sport-icon.svg?v=${process.env.NEXT_PUBLIC_FILE_VERSION_ID}#icon-sport-${sportName}${view === ViewEnum.mars && isColorful ? '-color' : ''}`}></use>
		</svg>
	)
};

export const AccountIcon = () => <Icon name="account" />
export const ArrowDownIcon = () => <Icon name="arrow-down" />
export const ArrowLeftIcon = () => <Icon name="arrow-left" />
export const ArrowMoreIcon = () => <Icon name="arrow-more" />
export const ArrowMoreDownIcon = () => <Icon name="arrow-more-down" />
export const ArrowRightIcon = () => <Icon name="arrow-right" />
export const ArrowUpIcon = () => <Icon name="arrow-up" />
export const ArrowPrevIcon = () => <Icon name="arrow-prev" />
export const BackspaceIcon = () => <Icon name="backspace" />
export const BaseballBatIcon = () => <Icon name="baseball-bat" />
export const BetslipBetIcon = () => <Icon name="betslip-bet" />
export const CashoutIcon = () => <Icon name="cashout" />
export const CheckboxIcon = () => <Icon name="checkbox" />
export const CheckboxActiveIcon = () => <Icon name="checkbox-active" />
export const CloseBigIcon = () => <Icon name="close-big" />
export const CloseLineIcon = () => <Icon name="close-line" />
export const CloseRoundIcon = () => <Icon name="close-round" />
export const CloseRoundLineIcon = () => <Icon name="close-round-line" />
export const CoffeeIcon = () => <Icon name="coffee" />
export const CoinsIcon = () => <Icon name="coins" />
export const CornerFirstIcon = () => <Icon name="corner-first" />
export const CornerFirst1Icon = () => <Icon name="corner-first-1" />
export const CornerFirst2Icon = () => <Icon name="corner-first-2" />
export const CornerLastIcon = () => <Icon name="corner-last" />
export const CornerLast1Icon = () => <Icon name="corner-last-1" />
export const CornerLast2Icon = () => <Icon name="corner-last-2" />
export const DepositIcon = () => <Icon name="deposit" />
export const DetailsIcon = () => <Icon name="details" />
export const DropdownIcon = () => <Icon name="dropdown" />
export const EditIcon = () => <Icon name="edit" />
export const EditOrderIcon = () => <Icon name="edit-order" />
export const EyeCloseIcon = () => <Icon name="eye-close" />
export const EyeOpenIcon = () => <Icon name="eye-open" />
export const FavoriteIcon = () => <Icon name="favorite" />
export const FavoriteOutlineIcon = () => <Icon name="favorite-outline" />
export const FilterIcon = () => <Icon name="filter" />
export const FilterLiveIcon = () => <Icon name="filter-live" />
export const FilterEarlyIcon = () => <Icon name="filter-early" />
export const FilterOutrightIcon = () => <Icon name="filter-outright" />
export const FilterTodayIcon = () => <Icon name="filter-today" />
export const FilterUpcomingIcon = () => <Icon name="filter-upcoming" />
export const FontsizeLargeIcon = () => <Icon name="fontsize-large" />
export const FontsizeSmallIcon = () => <Icon name="fontsize-small" />
export const FootballIcon = () => <Icon name="football" />
export const GameOverIcon = () => <Icon name="game-over" />
export const GoalFirstIcon = () => <Icon name="goal-first" />
export const GoalFirst1Icon = () => <Icon name="goal-first-1" />
export const GoalFirst2Icon = () => <Icon name="goal-first-2" />
export const GoalLastIcon = () => <Icon name="goal-last" />
export const GoalLast1Icon = () => <Icon name="goal-last-1" />
export const GoalLast2Icon = () => <Icon name="goal-last-2" />
export const GvIcon = () => <Icon name="gv" />
export const HistoryIcon = () => <Icon name="history" />
export const HomeCsIcon = () => <Icon name="home-cs" />
export const HomeEnIcon = () => <Icon name="home-en" />
export const LiveIcon = () => <Icon name="live" />
export const LoadingIcon = () => <Icon name="loading" />
export const LockIcon = () => <Icon name="lock" />
export const MatchPlayerIcon = () => <Icon name="match-player" />
export const MenuIcon = () => <Icon name="menu" />
export const MessageIcon = () => <Icon name="message" />
export const MoonIcon = () => <Icon name="moon" />
export const MyBetIcon = () => <Icon name="my-bet" />
export const ParlayIcon = () => <Icon name="parlay" />
export const NewWindowIcon = () => <Icon name="new-window" />
export const PinIcon = () => <Icon name="pin" />
export const PowerPlayIcon = () => <Icon name="power-play" />
export const QuestionIcon = () => <Icon name="question" />
export const QuickbetIcon = () => <Icon name="quickbet" />
export const RadioIcon = () => <Icon name="radio" />
export const RadioOnIcon = () => <Icon name="radio-on" />
export const RainIcon = () => <Icon name="rain" />
export const RedCrossIcon = () => <Icon name="red-cross" />
export const RefreshIcon = () => <Icon name="refresh" />
export const ResetIcon = () => <Icon name="reset" />
export const ResultIcon = () => <Icon name="result" />
export const ReturnIcon = () => <Icon name="return" />
export const RulesIcon = () => <Icon name="rules" />
export const SabaPointIcon = () => <Icon name="saba-point" />
export const SearchIcon = () => <Icon name="search" />
export const ShopIcon = () => <Icon name="shop" />
export const SignIcon = () => <Icon name="sign" />
export const SpeakerIcon = () => <Icon name="speaker" />
export const StatisticIcon = () => <Icon name="statistic" />
export const StreamerIcon = () => <Icon name="streamer" />
export const SuccessLineIcon = () => <Icon name="success-line" />
export const SunIcon = () => <Icon name="sun" />
export const TicketIcon = () => <Icon name="ticket" />
export const TicketShieldIcon = () => <Icon name="ticket-shield" />
export const TicketOddsBoostIcon = () => <Icon name="ticket-odds-boost" />
export const TicketExclamationIcon = () => <Icon name="ticket-exclamation" />
export const TicketRunningIcon = () => <Icon name="ticket-running" />
export const TicketErrorLineIcon = () => <Icon name="ticket-error-line" />
export const TicketSuccessLineIcon = () => <Icon name="ticket-success-line" />
export const ToolIcon = () => <Icon name="tool" />
export const TrashCanIcon = () => <Icon name="trash-can" />
export const TutorialIcon = () => <Icon name="tutorial" />
export const TypeCardIcon = () => <Icon name="type-card" />
export const TypeListIcon = () => <Icon name="type-list" />
export const VideoIcon = () => <Icon name="video" />
export const ViewMyBetIcon = () => <Icon name="view-my-bet" />
export const VolumeOffIcon = () => <Icon name="volume-off" />
export const VolumeOnIcon = () => <Icon name="volume-on" />
export const WalletIcon = () => <Icon name="wallet" />
export const WithdrawIcon = () => <Icon name="withdraw" />
export const LobbyIcon = () => <Icon name="lobby" />
export const LobbyOutlineIcon = () => <Icon name="lobby-outline" />
export const GripDotsIcon = () => <Icon name="grip-dots" />
export const CheckBoxActiveSquareIcon = () => <Icon name="check-box-active-square" />
export const CheckBoxSquareIcon = () => <Icon name="check-box-square" />
export const LockSolidIcon = () => <Icon name="lock-solid" />
export const PromotionIcon = () => <Icon name="promotion" />
export const SmpIcon = () => <Icon name="smp" />
export const BookingIcon = () => <Icon name="booking" />
export const MinusIcon = () => <Icon name="minus" />
export const PlusIcon = () => <Icon name="plus" />
export const ThumbUpIcon = () => <Icon name="thumb-up" />
export const ThumbDownIcon = () => <Icon name="thumb-down" />
export const ChannelChIcon = () => <Icon name="channel-ch" />
export const ChannelCsIcon = () => <Icon name="channel-cs" />
export const ChannelEnIcon = () => <Icon name="channel-en" />
export const ChannelIdIcon = () => <Icon name="channel-id" />
export const ChannelKoIcon = () => <Icon name="channel-ko" />
export const ChannelRuIcon = () => <Icon name="channel-ru" />
export const ChannelThIcon = () => <Icon name="channel-th" />
export const ChannelVnIcon = () => <Icon name="channel-vn" />
export const ChannelOtherIcon = () => <Icon name="channel-other" />
export const NotifIcon = () => <Icon name="notif" />
export const PenIcon = () => <Icon name="pen" />

// Mars Only
export const SettingsIcon = () => <Icon name="settings" />
export const UserIcon = () => <Icon name="user" />
export const MiniStreamingIcon = () => <Icon name="mini-streaming" />
export const TimeMachineIcon = () => <Icon name="time-machine" />
export const ChannelIcon = () => <Icon name="channel" />
export const FullScreenOnIcon = () => <Icon name="full-screen-on" />
export const FullScreenOffIcon = () => <Icon name="full-screen-off" />
export const CasinoIcon = () => <Icon name="casino" />
export const GameLobbyIcon = () => <Icon name="game-lobby" />
export const CollapseIcon = () => <Icon name="collapse" />
export const OddsTypeIcon = () => <Icon name="odds-type" />
export const DefaultStakeIcon = () => <Icon name="default-stake" />
export const QuickStakeIcon = () => <Icon name="quick-stake" />
export const BettingIcon = () => <Icon name="betting" />
export const EventsSortingIcon = () => <Icon name="events-sorting" />
export const SportsSortingIcon = () => <Icon name="sports-sorting" />
export const QuestionFillIcon = () => <Icon name="question-fill" />
export const SwitchVersionIcon = () => <Icon name="switch-version" />
export const EditPenIcon = () => <Icon name="edit-pen" />
export const LightBulbIcon = () => <Icon name="light-bulb" />
