import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ApiQueryPromise, ViewEnum } from '@/types';
import { getResponseJwtHeader } from '@/utils/web';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import queryKeys from '@/constants/query-keys';
import { useAccountInfo } from '@/hooks/query/account';
import { useBetslipStore } from '@/store/betslip';
import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { BetslipService } from '@/services/betslip.service';
import { uiService } from '@/services/ui.service';
import { isDesktop } from 'react-device-detect';
import { useSiteStore } from '@/store/site';
import { useAppearanceStore } from '@/store/appearance';
import { getAuthToken, useAuthActions } from '@/store/auth';
import { cloudUrlParser } from '@/utils/aws-domain-util';
import { UserInfo } from '@nf/types/account';

export const SPORT_TYPE = {
	ESports: 43,
	MuayThai: 44,
	NumberGame: 161,
	Happy5: 164,
	BitCoin: 175,
	Outright: 999,
} as const;

export const BET_TYPE = {
	Hdp: '1',
	TimeMachineOu: '228',
	TimeMachineHdp: '229',
	PenaltyShootoutCombo: '376',
	ScoreBoxHdp: '468',
	ScoreBoxOu: '469',
	DoubleQuarterWinner: '642',
	HdpOuResult: '646', // same as 'Match Handicap & Total'
	BackLay_1X2: '9538',
	BackLay_MatchWinner: '9539',
	BackLay_SuperOverWinner: '9540',
	BaseBall_8825: '8825',
} as const;

export const BETTING_CODE = {
	RejectTicket: -94,
	ExceedLimitSelection: -95,
	SameMatch: -96,
	NotSupportParlay: -97,
	Waiting: 0,
	Accepted: 1,
	OverMaxStakePerMatch: 7,
	CreditProblem: 8,
	DisableBetProblem: 10, // for single wallet
	ParlayMaxBetLessThanMinBet: 52,
	ParlayNoComboAvailable: 53,
	OverMaxPayoutPerMatch: 56,
	ParlayMinOdds: 60,
	ParlayComboByPool: 61,
	BlockDoubleBet: 65
} as const;

export const CricketPlayerBetTypeList = [
	9662, 9663, 9664, 9665, 9666, 9667, 9676, 9677, 9678, 9679, 9680, 9681, 9682, 9683, 9684, 9685, 9686, 9687, 9688, 9689,
	9690, 9691, 9692, 9693, 9694, 9695, 9712, 9713, 9714, 9715, 9716, 9717, 9718, 9719, 9720, 9721, 9730, 9731, 9732, 9733,
	9734, 9735, 9736, 9737, 9738, 9739, 9696, 9697, 9698, 9699, 9702, 9703, 9704, 9705
]

export const BackLayPayoutSetting = {
	betType: [BET_TYPE.BackLay_1X2, BET_TYPE.BackLay_MatchWinner, BET_TYPE.BackLay_SuperOverWinner] as string[],
	profitBetTeam: ['1b', '2b', 'xb', 'hb', 'ab'],
	liabiltyBetTeam: ['1l', '2l', 'xl', 'hl', 'al']
}

export interface CanBetResultModel {
	innerMsg: BetMsgModel[];
	outerMsg: BetMsgModel[];
	canBet: boolean;
	confirmBetMsg: BetMsgModel[];
}

export interface BetMsgModel {
	ticketKey: string;
	code: number;
	msg: string;
	msgResourceKey?: string;
	canBet: boolean;
	cssStatus?: 'closed' | 'same-match' | 'warning' | undefined;
}

export interface BettingCodeMap {
	title: string;
	canBet: boolean;
	msg?: string;
	msgResourceKey?: string;
	cssStatus?: 'closed' | 'same-match' | 'warning';
	showToast?: boolean;
}

/**
 * BETTING CODE 定義
 * @description 前端自訂請以 "負數" 定義, 其餘皆為 betting api 實際會回傳的 error code
 * @remark ※注意: canBet 狀態已對照舊 code, 不可隨意更動
 */
export const BETTING_CODE_MAP = new Map<number, BettingCodeMap>([
	[-94, { title: 'RejectTicket', canBet: false }],	// only show at ConfirmBet
	[-95, { title: 'ExceedLimitSelection', canBet: true, msgResourceKey: 'TicketOver' }],	// 上限20張
	[-96, { title: 'SameMatch', canBet: true, msgResourceKey: 'lbl_ESOSrelate', cssStatus: 'same-match' }],	// 相同賽事
	[-97, { title: 'NotSupportParlay', canBet: true, msgResourceKey: 'lbl_EDSupport' }],	// 不支援串關
	[-98, { title: 'OddsChangeForOnlyParlay', canBet: true, msgResourceKey: 'lbl_oddchange' }],
	[0, { title: 'Waiting', canBet: true }],
	[1, { title: 'Accepted', canBet: true }],
	[2, { title: 'Suspended', canBet: true }],	// only show at ConfirmBet
	[6, { title: 'MatchClosed', canBet: false }],
	[7, { title: 'OverMaxPerMatch', canBet: true, cssStatus: 'closed', showToast: true }],
	[8, { title: 'CreditProblem', canBet: true, cssStatus: 'closed', showToast: true }], // insufficient balance
	[10, { title: 'DisableBetProblem', canBet: true }], // for single wallet (include single wallet api error code 201~205,502,506,507)
	[15, { title: 'OddsChange', canBet: true }],
	// [37, { title: 'DisabledBet', canBet: false }],
	[41, { title: 'InPlayChanged', canBet: true }], // for e-sports
	// [43, { title: 'OverMaxPerBet', canBet: true }],
	[44, { title: 'ScoreChanged', canBet: true }], // api return code=15
	[45, { title: 'LineChanged', canBet: true }], // api return code=15
	[46, { title: 'ClosePrice', canBet: false }], // api return code=6
	[47, { title: 'MarketClosed', canBet: false }], // api return code=6
	[48, { title: 'OverRepeatedBet', canBet: false }],
	[49, { title: 'BetIntervalBlock', canBet: true }],
	[52, { title: 'ParlayMaxBetLessThanMinBet', canBet: false, cssStatus: 'closed' }], // parlay max payout
	[53, { title: 'ParlayNoComboAvailable', canBet: false, cssStatus: 'closed' }], // parlay max payout
	[55, { title: 'DummyErrorCodeUseByBettingModule', canBet: false }], // disable outright
	[56, { title: 'OverMaxPayoutPerMatch', canBet: true, cssStatus: 'closed', showToast: true }],
	[58, { title: 'DisableParlay', canBet: true }], // CC限制
	[60, { title: 'ParlayMinOdds', canBet: false, cssStatus: 'warning' }],
	[61, { title: 'ParlayComboByPool', canBet: false, cssStatus: 'warning' }],
	[65, { title: 'BlockDoubleBet', canBet: true }],
	// [90, { title: 'TransIdError', canBet: true }],
]);

export type BetSourceType = 'promotion' | undefined;

/** 合併投注, 分開投注被選取時使用 */
export type BetslipTab = 'single' | 'parlay';

/** 基本樣式, 懸浮樣式使用 */
export enum BetslipStyle {
	basic = 0,
	floating = 1
}

/** Betslip - UI 元件類型 */
export type BetslipType = 'single' | 'multi' | 'quickbet' | 'empty';

/** stake per bet 放置類型 */
export type StakePerBetType = 'multi' | 'parlay';

/** 下注後是否收票可能的狀態 */
export type AcceptTicketStatus = 'running' | 'waiting' | 'waitting' | 'reject';

/**可選擇的優惠券類型 for Saba Reward Program */
export type VoucherType = 'OddsBooster' | 'RiskFreeBet' | 'None';

export const BetAction = {
	Default: 0,
	QuickBet: 1,
	TelegramBet: 2,
	BetAgain: 4,
	ReservationBet: 8,
	BetGenerator: 16,
	TournamentWidget: 32,
	MasterRank: 64
};

export const bettingInputMaxLength = 8;
export const selectTicketMaxLangth = 20;

export interface AddTicketEventInterface {
	ticketModel: OddsTicketModel;
	oddsKey: string;
}

export interface RemoveTicketEventInterface {
	oddsKey: string;
}

/** Betslip - for odds button add ticket */
export interface OddsTicketModel {
	Ascore: number;
	ChoiceValue?: number;
	Hdp1: number;
	Hdp2: number;
	Hscore: number;
	LeagueId: number;
	LeagueGroupId: string;
	Line: string;
	MRPercentage: unknown;
	Matchid: number;
	OtherHdp: number | undefined;
	Parentid: number;
	Parentmatchid: number;
	Resourceid: string;
	SrcOddsInfo?: string | null;
	away: string;
	betteam: string;
	bettype: number;
	errorcode?: string;
	gameid: number;
	isMatchHasCashOut: boolean;
	home: string;
	isMMR: boolean;
	odds: string;
	oddsid: number;
	pty: number;
	srcLine: number;
	stake?: number;
	Cashout?: number;
	type: 'OT' | 'OU';
	originalPrice: number;
	HomeId: number;
	AwayId: number;
}

export type BettingCommon = {
	ErrorCode: number;
	ErrorMsg: string;
};

/** Betslip - 單一 ticket (from Multi) */
export type TicketModel = {
	key: string;
	stake: number;
	sync: boolean; // for determine this ticket has been sync from db
	payout: number;
	minBet: number;
	maxBet: number;
	Minbet: string;
	Maxbet: string;
	oldOdds: string; // for test
	newOdds: string; // for test
	hasCashOut: boolean; // for test

	// === api response ===
	AutoAcceptSec: string;
	AwayId: number;
	AwayName: string; // default=""
	Bet: string;
	BetHintMsg: string; // default=""
	BetID: string; // default=null
	BetRecommends: any;
	BetTimeConstraint: {
		EndTimestamp: number;
	};
	Betteam: string; // default=""
	Bettype: string; // default="0"
	BettypeName: string; // default=""
	BonusID: number;
	BonusType: number;
	ChoiceValue: string; // default="" (some html string)
	Code: number; // default=-99
	Common?: BettingCommon;
	DisplayHDP: string; // default="0"
	PreDisplayOdds: string;
	DisplayOdds: string; // default="0"
	// 以下兩欄位是用於parlay tab時要顯示parlayOdds, 可是目前架構只會用到single ticket因此將parlay odds開到此欄位上
	PreDisplayParlayOdds: string;
	DisplayParlayOdds: string; // default="0"
	DisplayOddsPair: string; // default="0"
	DisplayTime: string;
	ErrorCode: number;
	GameName: string; // default=""
	Guid: string;
	HasParlay: boolean; // default=false
	Hdp1: number; // default=0
	Hdp2: number; // default=0
	HomeId: number;
	HomeName: string; // default=""
	IsInPlay: boolean; // default=false
	IsLive: boolean; // default=false
	CheckWaitingTicket: boolean;
	LeagueId: number;
	LeagueGroupId: number;
	LeagueName: string; // default=""
	Line: string;
	LiveAwayScore: number; // default=999
	LiveHomeScore: number; // default=999
	LiveScore: boolean; // default=false
	MRPercentage: string; // default=""
	MatchCode: any;
	Matchid: number; // default=-1
	Message: string | null;
	Nolan: boolean;
	OddsBeforeOddsBoost: any;
	OddsBoost: any;
	OddsID: number; // default=0
	OddsInfo: string | null; // default=""
	OddsStatus: any;
	originalPrice: number;
	PDHyalpsiD: string;
	ParentMatchid: number;
	PriceType: number;
	QuickBet: string;
	RecommendType: number;
	SeqNo: number;
	SportName: string; // default=""
	SportType: number; // default=0
	SrcOddsInfo?: any;
	SuggestStake: number;
	Tenet: boolean;
	TicketTime: number;
	TicketType: string; // default="-"
	UseBonus: number;
	isLineChange: boolean;
	isMMR: boolean; // default=false
	isOddsChange: boolean; // default=false
	isScoreChange: boolean;
	riaPsddOyalpsiD: string;
	sddOyalpsiD: any;
	sinfo: string; // default=""
	TransId?: string;
	ACCode?: string;
	specialChoices?: Array<string>;
	CustomCurrencyName?: string;
	IsCashoutEnabled?: boolean;

	// === betting related model ===
	// programID: string; // default=""
	// raceNum: string; // default=""
	// runner: string; // default=""
	// IsCashOutTicket: boolean; // default=false
	// IsHomeFavor: boolean; // default=false
	// IsAwayFavor: boolean; // default=false
	// HasSameMatch: boolean; // default=false
	// acceptBetterOdds: boolean; // default=null
	// bonusType: string; // default=""
	// bonusId: string; // default=""
	// canRetain: boolean; // default=false
	// category: number; // default=-1
};

export type BetTicketModel = {
	Key: string;
	DisplayOdds: string;
	OddsBeforeOddsBoost: any | null; // Placeholder, needs proper type
	OddsBoost: any | null; // Placeholder, needs proper type
	MRPercentage: number;
	DisplayHDP: string;
	Hdp1: number;
	Hdp2: number;
	OddsInfo: any | null; // Placeholder, needs proper type
	sinfo: string;
	SrcOddsInfo: any | null; // Placeholder, needs proper type
	BetID: string;
	LeagueGroupId: number;
	BetTeam: any; // Placeholder, needs proper type
	ChoiceValue: any; // Placeholder, needs proper type
	TransId_Cash: string;
	Code: number;
	Message: string;
	isOddsChange: boolean;
	isLineChange: boolean;
	isScoreChange: boolean;
	Stake: string;
	Stake_Cash: string;
	Stake_Bonus: string;
	ActualStake_Cash: string;
	ActualStake_Bonus: string;
	LiveHomeScore: number;
	LiveAwayScore: number;
	TicketStatus: number;
	IsInPlay: boolean;
	IsLive: boolean;
	HasParlay: boolean;
	PriceType: number;
	TotalPerBet: number;
	FinalBalance: string;
	AdjustedMaxBet: number;
	CheckWaitingTicket: boolean;
	BetDelaySec: number;
	ErrorCode: number;
	TicketJson: string;
	Common: BettingCommon;
	ChkSetting: any; // Placeholder, needs proper type
	ExtInfo: any; // Placeholder, needs proper type
	PT: any; // Placeholder, needs proper type
	Commission: any; // Placeholder, needs proper type
	Reserved_1: number;
	Reserved_2: number;
	BetTimeConstraint: any; // Placeholder, needs proper type
	BetActionTypeEnum: number;
	SportTypeEnum: number;
	SiteAutobookieGroup: number;
	OddsGroupGap: number;
	SingleWayAutobookieGap: number;
	UserActualrate: number;
	ErrorCodeEnum: number;
	FinalBetDelaySec: number;
	SingleWalletFinalBalance: number;
	TransactionTime: string;
	CreditSiteSpread: number;
	IsFavorTeam: boolean;
	GlobalShowTime: string;
	DisplayTime: string;
	CurrencySpread: number;
	MarketEnum: number;
	BetRecommendation: any[]; // Placeholder, needs proper type
	BetRecommends: any[]; // Placeholder, needs proper type
	ACCode: any; // Placeholder, needs proper type
}

export type MultiTicketsModel = {
	Common: BettingCommon;
	ErrorMsg: string;
	ItemList: Array<BetTicketModel>;
};

/** Betslip - 串關 ticket (from Parlay) */
export type ParlayTicketModel = {
	key: string;
	stake: number;
	payout: number;

	// === api response ===
	BetCheck: number;
	BetCount: number;
	BetTeam: number;
	CanBet: boolean;
	DoPlaceBet: boolean;
	ErrorCode: number;
	ErrorMessageDetails: Map<string, string>;
	ErrorMsg: string;
	MaxBet: number;
	MaxPayout: number;
	Name: string;
	Odds: number;
	Type: number;
	isComboParlay: boolean;
	isLucky: boolean;
	isMixParlay: boolean;
	isSystemParlay: boolean;
	Stake: number;
	TotalStake: number;
}

/** Betslip - 多筆串關的資料集 (from Parlay) */
export type ParlayTicketsModel = {
	CanBetTicketCnt: number;
	MaxBet: string;
	MinBet: string;
	Common: BettingCommon;
	Code: number;
	Message: string;
	Tickets: Array<TicketModel>,
	comboData: Array<ParlayTicketModel>;
	CheckWaitingTicket: boolean;
	TransId_Combo: string;
	TransId_Lucky: string;
	TransId_System: string;
};

/** Betslip - 多筆串關的資料集 (from Parlay) */
export type BetParlayTicketsModel = {
	CanBetTicketCnt: number;
	MaxBet: string;
	MinBet: string;
	Common: BettingCommon;
	Code: number;
	Message: string;
	FinalBalance: string;
	ItemList: Array<BetTicketModel>,
	comboData: Array<ParlayTicketModel>;
	CheckWaitingTicket: boolean;
	TransId_Combo: string;
	TransId_Lucky: string;
	TransId_System: string;
};

/** Betslip - API GetComboTickets Response */
export type SyncTicketsResponse = {
	MultiData: Array<TicketModel>,
	ParlayData: ParlayTicketsModel
}

/** Betslip - API DoComboTicketsBet Response */
export type BetTicketsResponse = {
	MultiData: MultiTicketsModel,
	ParlayData: BetParlayTicketsModel
}

export type ParlayTicketStatus = {
	Master: TicketStatus,
	Slave: Array<ParlaySubTicket>
}

export type TicketStatus = {
	status: string,
	ReasonID?: number,
	refno: number
}

export type ParlaySubTicket = {
	MatchId: number
	Status: string,
	ReasonIdm?: number,
	ParentMatchId: number
}

/** 組成呼叫 API 用的 Request Data */
const convertTicketToFormData = (formData: FormData, tickets: Array<TicketModel>, prefixLayer: string): FormData => {

	tickets.forEach((ticket, index) => {
		formData.append(`${prefixLayer}[${index}][type]`, ticket.TicketType);
		formData.append(`${prefixLayer}[${index}][bettype]`, ticket.Bettype);
		formData.append(`${prefixLayer}[${index}][oddsid]`, ticket.OddsID.toString());
		formData.append(`${prefixLayer}[${index}][odds]`, ticket.DisplayOdds);
		formData.append(`${prefixLayer}[${index}][Line]`, ticket.Line);
		formData.append(`${prefixLayer}[${index}][Hscore]`, ticket.LiveHomeScore.toString());
		formData.append(`${prefixLayer}[${index}][Ascore]`, ticket.LiveAwayScore.toString());
		formData.append(`${prefixLayer}[${index}][Matchid]`, ticket.Matchid.toString());
		formData.append(`${prefixLayer}[${index}][betteam]`, ticket.Betteam);
		formData.append(`${prefixLayer}[${index}][stake]`, (ticket.stake || 0).toString());
		formData.append(`${prefixLayer}[${index}][QuickBet]`, ticket.QuickBet);
		formData.append(`${prefixLayer}[${index}][ChoiceValue]`, ticket.ChoiceValue);
		formData.append(`${prefixLayer}[${index}][home]`, ticket.HomeName);
		formData.append(`${prefixLayer}[${index}][away]`, ticket.AwayName);
		formData.append(`${prefixLayer}[${index}][gameid]`, ticket.SportType.toString());
		formData.append(`${prefixLayer}[${index}][isMMR]`, (ticket.isMMR || false).toString());
		formData.append(`${prefixLayer}[${index}][MRPercentage]`, ticket.MRPercentage && !isNaN(Number(ticket.MRPercentage)) ? ticket.MRPercentage : '0');
		formData.append(`${prefixLayer}[${index}][GameName]`, ticket.GameName);
		formData.append(`${prefixLayer}[${index}][SportName]`, ticket.SportName);
		formData.append(`${prefixLayer}[${index}][IsInPlay]`, ticket.IsInPlay.toString());
		formData.append(`${prefixLayer}[${index}][isLive]`, (ticket.IsLive || false).toString());
		formData.append(`${prefixLayer}[${index}][SrcOddsInfo]`, ticket.SrcOddsInfo || '');
		formData.append(`${prefixLayer}[${index}][pty]`, (ticket.PriceType ?? 0).toString());
		formData.append(`${prefixLayer}[${index}][Hdp1]`, ticket.Hdp1.toString());
		formData.append(`${prefixLayer}[${index}][Hdp2]`, ticket.Hdp2.toString());
		formData.append(`${prefixLayer}[${index}][sinfo]`, ticket.sinfo);
		formData.append(`${prefixLayer}[${index}][hasCashOut]`, (ticket.hasCashOut || false).toString());
		formData.append(`${prefixLayer}[${index}][RecommendType]`, ticket.RecommendType.toString());
		formData.append(`${prefixLayer}[${index}][Minbet]`, (ticket.minBet || 0).toString());
		formData.append(`${prefixLayer}[${index}][LeagueGroupId]`, ticket.LeagueGroupId.toString());

		formData.append(`${prefixLayer}[${index}][Guid]`, ticket.Guid);
		formData.append(`${prefixLayer}[${index}][TicketTime]`, ticket.TicketTime.toString());

	});

	return formData;
}

/** 呼叫 API - GetComboTickets */
const syncTicket = async (tickets: Array<TicketModel>, useVoucher: VoucherType, useSabaCoin?: boolean): Promise<ApiQueryPromise<SyncTicketsResponse>> => {
	const authToken = getAuthToken();
	const { ctcdDomain } = useSiteStore.getState().apiDomain;

	let formData = new FormData();

	formData = convertTicketToFormData(formData, tickets, 'ItemList');

	if (tickets.length == 1 && useVoucher != 'None') {
		if (useVoucher == 'OddsBooster') {
			formData.append('rewardSystemVoucherType', '1');
		} else if (useVoucher == 'RiskFreeBet') {
			formData.append('rewardSystemVoucherType', '2');
		}
	}
	if (useSabaCoin) {
		formData.append('isUseSabaCoin', 'true');
	}

	const options = {
		body: formData
	};

	const response = await fetchComposer.postWithBearerBase(cloudUrlParser(`${ctcdDomain}/api/Betting/GetComboTickets`), options)(authToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

/** 呼叫 API - DoComboTicketsBet */
const betTicket = async (
	tickets: Array<TicketModel>,
	parlayData: ParlayTicketsModel | null,
	user: UserInfo | null | undefined,
	useVoucher: VoucherType,
	isQuickBet: boolean,
	useSabaCoin: boolean
): Promise<ApiQueryPromise<BetTicketsResponse>> => {
	const authToken = getAuthToken();
	const { apiDomain: { ctcdDomain }, isParlayMode } = useSiteStore.getState();
	const { view } = useAppearanceStore.getState();
	const { betAction, betslipTab } = useBetslipStore.getState();
	const canBetSingle = (user?.BettingMode === 0 || betslipTab === 'single') || tickets.length === 1;
	const canBetParlay = (user?.BettingMode === 0 || betslipTab === 'parlay') && parlayData?.Tickets?.length;
	let formData = new FormData();

	const bettingTickets = tickets.filter(ticket => ticket.stake);
	let bettingParlayTickets: ParlayTicketModel[] = [];

	if (parlayData?.Tickets?.length) {
		bettingParlayTickets = parlayData.comboData?.filter(ticket => ticket.CanBet && ticket.stake);
	}

	// // 特殊邏輯：分離模式下僅有mix parlay有stake的情況下, 要無視tab替該mix parlay下注
	// if (bettingTickets.length === 0 && bettingParlayTickets?.length === 1 && bettingParlayTickets[0].isMixParlay === true) {
	// 	canBetParlay = true;
	// }

	if (canBetSingle) {
		formData = convertTicketToFormData(formData, bettingTickets, 'ticketData[ItemList]');
	}

	if (canBetParlay && bettingParlayTickets.length) {
		formData = convertTicketToFormData(formData, parlayData?.Tickets ?? [], 'comboData[ItemList]');
		bettingParlayTickets?.forEach((parlayTicket, index) => {
			formData.append(`comboData[ComboLists][${index}][Type]`, parlayTicket.Type.toString());
			formData.append(`comboData[ComboLists][${index}][BetCount]`, parlayTicket.BetCount.toString());
			formData.append(`comboData[ComboLists][${index}][Stake]`, parlayTicket.stake.toString());
		});
	}

	// accept better odds
	formData.append('acceptBetterOdds', (user?.BetterOdds ?? false).toString());

	// reward
	if (bettingTickets.length == 1 && useVoucher != 'None') {
		if (useVoucher == 'OddsBooster') {
			formData.append('rewardSystemVoucherType', '1');
		} else if (useVoucher == 'RiskFreeBet') {
			formData.append('rewardSystemVoucherType', '2');
		}
	}

	const uuidValue = uuid();
	formData.append('BettingId', uuidValue);
	formData.append('BetAction', (isQuickBet ? BetAction.QuickBet : betAction).toString());

	if (useSabaCoin) {
		formData.append('isUseSabaCoin', 'true');
	}
	const options = {
		body: formData
	};

	const response = await fetchComposer.postWithBearerBase(cloudUrlParser(`${ctcdDomain}/api/Betting/DoComboTicketsBet`), options)(authToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	if (view === ViewEnum.mars && canBetParlay && bettingParlayTickets.length) {
		BetslipService.setMarsParlayBetKafka(view, isParlayMode);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
}

/** 呼叫 API - GetStatusByTransidJson */
const getSingleStatus = async (transId: string): Promise<ApiQueryPromise<TicketStatus>> => {
	const { ctcdDomain } = useSiteStore.getState().apiDomain;
	const authToken = getAuthToken();

	const response =
		await fetchComposer.getWithBearer(`${ctcdDomain}/Statement/GetStatusByTransidJson?transid=${transId}`)(authToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

/** 呼叫 API - GetParlayStatusByTransidJson */
const getParlayStatus = async (transId: string): Promise<ApiQueryPromise<ParlayTicketStatus>> => {
	const { ctcdDomain } = useSiteStore.getState().apiDomain;
	const authToken = getAuthToken();

	const response =
		await fetchComposer.getWithBearer(`${ctcdDomain}/Statement/GetParlayStatusByTransidJson?transid=${transId}`)(authToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useTicketStatus = (transId: string) => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.TICKET_STATUS + transId],
		queryFn: () => getSingleStatus(transId),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};

		updateJwt();
	}, [data?.jwtToken]);

	return {
		ticketStatus: data?.data.Data,
		isLoading,
		isError,
		error,
		refetch
	};
}

export const useParlayStatus = (transId: string) => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.PARLAY_STATUS + transId],
		queryFn: () => getParlayStatus(transId),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};

		updateJwt();
	}, [data?.jwtToken]);

	return {
		parlayStatus: data?.data.Data,
		isLoading,
		isError,
		error,
		refetch
	};
}

export const useTicketInfo = (tickets: Array<TicketModel>) => {
	const [useVoucher, useSabaCoin] = useBetslipStore(state => [state.useVoucher, state.useSabaCoin]);

	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.TICKET_INFO + tickets.length],
		queryFn: () => syncTicket(tickets, useVoucher, useSabaCoin),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};

		updateJwt();
	}, [data?.jwtToken]);

	return {
		syncTicketResponse: data?.data.Data,
		isLoading,
		isError,
		error,
		refetch
	};
};

export const useTicketBet = () => {
	const { user } = useAccountInfo();

	const [
		currentMultiTicketModel,
		currentParlayTicketModel,
		useVoucher,
		useSabaCoin
	] = useBetslipStore(state => [
		state.currentMultiTickets,
		state.currentParlayTicketModel,
		state.useVoucher,
		state.useSabaCoin
	]);

	const { data, isFetching, isError, error, refetch } = useQuery({
		queryKey: [`${queryKeys.TICKET_BET}_${currentMultiTicketModel.length}_${currentParlayTicketModel?.Tickets?.length}`],
		queryFn: () => betTicket(currentMultiTicketModel, currentParlayTicketModel, user, useVoucher, false, useSabaCoin),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false,
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};
		updateJwt();
	}, [data?.jwtToken]);

	return {
		betTicketResponse: data?.data.Data,
		isError,
		isFetching,
		error,
		refetch
	};
};

export const useQuickBet = (oddsKey: string) => {
	const queryClient = useQueryClient();
	const { user } = useAccountInfo();
	const { updateAuthToken } = useAuthActions();

	const quickBetStake = user?.SportsQuickBetStake as number;

	//const [isShowedMessage, setIsShowedMessage] = useState(false);

	const [
		quickBetTicket,
		clearQuickBetTicket
	] = useBetslipStore(state => [
		state.quickBetTicket,
		state.clearQuickBetTicket
	]);

	let ticketModel = {
		...quickBetTicket
	} as TicketModel;

	if (ticketModel.key == oddsKey && quickBetStake <= 0) {
		uiService.processQuickBetButton('');
		document.dispatchEvent(new CustomEvent('show-toast-msg', {
			detail: { resourceKey: 'lbl_SetQuickBetStake' }
		}));
		clearQuickBetTicket();
	}

	const { data: getTicketResponse, isFetching: getTicketFetching, refetch } = useQuery({
		queryKey: [queryKeys.TICKET_INFO, oddsKey],
		queryFn: () => syncTicket([ticketModel], 'None'),
		cacheTime: 0,
		staleTime: 0,
		refetchOnWindowFocus: false,
		enabled: ticketModel.key == oddsKey && quickBetStake > 0
	});

	useEffect(() => {
		const updateJwt = () => {
			if (getTicketResponse?.jwtToken) {
				updateAuthToken(getTicketResponse.jwtToken);
			}
		};
		updateJwt();
	}, [getTicketResponse?.jwtToken]);

	if (getTicketResponse?.data) {

		const dbTicket = getTicketResponse.data.Data?.MultiData?.[0];
		ticketModel = BetslipService.patchTicketModel(ticketModel, (dbTicket ?? {} as TicketModel));

		ticketModel.stake = quickBetStake;

		// if (ticketModel.stake < ticketModel.minBet) {
		// 	ticketModel.stake = ticketModel.minBet;
		// 	if (!isShowedMessage) {
		// 		setToastMsg(`Lower than min bet. Your stake is adjusted to ${ticketModel.minBet} now.`);
		// 		setIsShowedMessage(true);
		// 	}
		// } else if (ticketModel.stake > ticketModel.maxBet) {
		// 	ticketModel.stake = ticketModel.maxBet;
		// 	if (!isShowedMessage) {
		// 		setToastMsg(`Higher than max bet. Your stake is adjusted to ${ticketModel.maxBet} now.`);
		// 		setIsShowedMessage(true);
		// 	}
		// }
	}

	const { data: doBetResponse, isFetching: doBetFetching } = useQuery({
		queryKey: [queryKeys.TICKET_BET, oddsKey, ticketModel?.TicketTime],
		queryFn: () => betTicket([ticketModel], null, user, 'None', true, false),
		cacheTime: 0,
		staleTime: 0,
		refetchOnWindowFocus: false,
		enabled: !!ticketModel?.sync && !!ticketModel?.OddsID && oddsKey == ticketModel?.key
	});

	useEffect(() => {
		const updateJwt = () => {
			if (doBetResponse?.jwtToken) {
				updateAuthToken(doBetResponse.jwtToken);
			}
		};
		updateJwt();
	}, [doBetResponse?.jwtToken]);

	useEffect(() => {
		const multiTickets = doBetResponse?.data.Data?.MultiData.ItemList;
		if (multiTickets && ticketModel?.key == oddsKey) {
			document.dispatchEvent(new CustomEvent('show-toast-msg', {
				detail: { msg: multiTickets[0].Message as string }
			}));
		}

		clearQuickBetTicket();

		// refetch my bets
		if (isDesktop && doBetResponse?.data) {
			queryClient.invalidateQueries({
				queryKey: [queryKeys.UNSETTLED_CATEGORY_INFO],
			});
			queryClient.invalidateQueries({
				queryKey: [queryKeys.UNSETTLED_STATUS_COUNT_INFO]
			});
		} else if (doBetResponse?.data) {
			queryClient.invalidateQueries({
				queryKey: [queryKeys.UNSETTLED_STATUS_COUNT_INFO]
			});
		}
	}, [doBetResponse?.data])

	const errorCode = doBetResponse?.data.Data?.MultiData.ItemList[0].ErrorCode;
	const checkWaitingTicket = doBetResponse?.data.Data?.MultiData.ItemList[0].CheckWaitingTicket;

	const status: AcceptTicketStatus = errorCode != 0 ? 'reject' :
		checkWaitingTicket ? 'waiting' : 'running';

	return {
		status: doBetResponse?.data.Data ? status : null,
		isLoading: getTicketFetching || doBetFetching,
		doQuickBet: refetch
	}
}
